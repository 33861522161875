<template>
    <div class="pa-0">
        <v-col :class="{'pa-2':!sidebar,'pa-0':sidebar} ">
            <v-text-field hide-details
                          :solo="searchFocused"
                          :solo-inverted="!searchFocused"
                          :flat="!searchFocused"
                          @focus="searchFocused=true"
                          @blur="searchFocused=false"
                          v-model="contactName"
                          ref="searchField"
                          append-icon="mdi-magnify"
                          :placeholder="$t('$vuetify.Messenger.search')"
                          dense
                          class="smooth-transition"
            ></v-text-field>
        </v-col>

        <v-list subheader class="mt-1">
            <v-list-item-group v-model="activeChat">
                <template v-for="(item, index) in filteredConversations">
                    <v-list-item
                            :key="`parent${index}`"
                            :value="item.id"
                            @click="setActiveConversation(item)"
                            style="padding:0 10px"
                            :class="{'red':activeChatId==item.id}"
                    >
                        <v-list-item-avatar class="ml-2" height="50" width="50" color="white--text ">
                            <v-avatar>
                                <v-img :src='item.avatar!=null ?
                                item.avatar : defaultAvatar' alt="" />
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                              <div class="text-truncate" style="max-width: 160px;float: right">{{item.full_name}}</div>
                              <v-spacer></v-spacer>
                              <template v-if="item.last_message != null">
                                <span class="messenger-side-date">
                                  {{item.last_message.date}}
                                </span>
                              </template>
                            </v-list-item-title>
                            <v-list-item-subtitle class="pt-1">
                                <template v-if="item.last_message != null ">
                                    <div class="text-truncate">{{item.last_message.contents}}</div>
                                </template>
                                <template v-else>
                                  <div>...</div>
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <template v-if="item.conversation != null">
                            <v-list-item-icon class="messenger-side-badge" v-if="hasNewMessage(item.conversation.id)">
                                <v-badge color="orange" :content="newMessageCount(item.conversation.id)"></v-badge>
                            </v-list-item-icon>
                        </template>

                    </v-list-item>
                    <v-divider
                            :key="`chatDivider${index}`"
                            class="my-0"
                    />
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';

    export default {
        props: {
            conversations: {
                default: () => {
                    return []
                }
            },
            sidebar: {
                default: false
            }
        },
        computed: {
            ...mapGetters(['getNewMessagesConversations']),
            activeChatId() {
                if (this.activeChat != null || this.activeChat != undefined) {
                    return this.activeChat.id;
                } else {
                    return null;
                }
            },
            filteredConversations() {

                if (this.contactName == null || this.contactName == '') {
                    return this.conversations;
                }

                return this.conversations.filter((item) => {
                    return item.full_name.includes(this.contactName) ? item : null;
                });

            }
        },
        methods: {
            hasNewMessage(input) {
                return this.getNewMessagesConversations.find(item => item.conversationId == input);
            },
            newMessageCount(input) {
                let conversation = this.getNewMessagesConversations.find(item => item.conversationId == input);
                return conversation.newMessageCount;
            },
            setActiveConversation(item) {

                if (!this.sidebar) {

                    if (this.activeChat == undefined || this.activeChat != item.id) {
                        let x = {
                            id: item.id,
                            title: item.title
                        };
                        this.$emit('conversationSelected', x);
                    } else if (this.activeChat == item.id) {
                        this.$emit('conversationDeSelected');
                    }

                } else {

                    let x = {
                        id: item.id,
                        title: item.title
                    };
                    this.$emit('conversationSelected', x);
                }
            }
        },
        data() {
            return {
                contactName: "",
                searchFocused: false,
                activeChat: null,
                defaultAvatar: require("./../../assets/avatar-default.png")
            }
        }

    }
</script>

<style scoped>

</style>