<template>
    <messenger
            v-if="$store.getters.userHasRolesArray(['counselor','customer']) && contactsApi!=null"
            :contactsGetAction="contactsApi"
            :sendMessageAction="sendMessage"
            :getConversationAction="getConversationMessage"
            :mode="mode"
    ></messenger>
<!--    <admin-messenger-->
<!--            v-else-if="$store.getters.userHasRole('admin')"-->
<!--            :contactsGetAction="contactsApi"-->
<!--            :sendMessageAction="sendMessage"-->
<!--            :getConversationAction="getConversationMessage"-->
<!--            :mode="mode"-->
<!--    >-->
<!--    </admin-messenger>-->

</template>

<script>
    /*eslint-disable*/
    import messenger from '@/components/chat/messenger.vue';
    // import AdminMessenger from '@/components/chat/AdminMessenger.vue';

    export default {
        created() {
            if (this.$store.getters.userHasRole('customer')) {
                this.mode = 'customer';
                this.contactsApi = 'userGetContacts';
                this.sendMessage = 'userSendConversationMessage';
                this.getConversationMessage = 'userGetConversationMessages';
            } else if (this.$store.getters.userHasRole('counselor')) {
                this.mode = 'counselor';
                this.contactsApi = 'counselorGetContacts';
                this.sendMessage = 'counselorSendConversationMessage';
                this.getConversationMessage = 'counselorGetConversationMessages';
            } else if (this.$store.getters.userHasRole('admin')) {
                this.mode = 'admin';
                this.contactsApi = 'adminGetContacts';
                this.sendMessage = 'adminSendConversationMessage';
                this.getConversationMessage = 'adminGetConversationMessages';
            }
        },
        components: {
            messenger,
            // AdminMessenger
        },
        computed: {
            // userGetContacts
            // userSendConversationMessage
            // userGetConversationMessages
        },
        data() {
            return {
                mode: null,
                contactsApi: null,
                sendMessage: null,
                getConversationMessage: null,
            }
        }
    }
</script>

<style scoped>

</style>